<div class="main-container row m-0 p-0">
  <div class="profile-container col-12" style="position: relative">
    <div class="profile-avatar">
      <div>
        {{ user.first_name.charAt(0) + " " + user.last_name.charAt(0) }}
      </div>
    </div>
    <div class="company-profile">
      <!-- <h1 class="text-xl font-bold text-buupass-pink">User Profile</h1> -->
      <!-- Company Information Section -->
      <div class="company-info">
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <div class="d-flex flex-row align-items-center profile-entry">
              <img src="./assets/Menu sections/icons/person_icon.svg" alt="" />
              <p style="padding-left: 20px">
                {{ user.first_name + " " + user.last_name }}
              </p>
            </div>
            <div class="d-flex flex-row align-items-center profile-entry">
              <img
                src="./assets/Menu sections/icons/envelope_icon.svg"
                alt=""
                style="color: orange"
              />
              <p style="padding-left: 20px">{{ user.email }}</p>
            </div>
            <div class="d-flex flex-row align-items-center profile-entry">
              <img
                src="./assets/Menu sections/icons/building_icon.svg"
                alt=""
              />
              <p style="padding-left: 20px">{{ user.company_name }}</p>
            </div>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>

      <!-- Approval Settings Tab -->
      <div class="approval-settings pt-2 pb-4 row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <ng-container *ngIf="isFetchingApprovalStages">
            <div class="loader"></div>
          </ng-container>
          <div
            *ngIf="!isFetchingApprovalStages"
            class="bg-gray-100 p-4 rounded-lg d-flex flex-column justify-around w-full flex-wrap"
          >
            <div class="profile-info-container">
              <div class="input-col">
                <label for="">Phone Number *</label>
                <input
                  type="text"
                  value="{{ userProfile.phone_number }}"
                  [placeholder]="user.phone_number"
                  disabled
                />
              </div>
              <div
                class="p-2 pt-4 bg-gray-100 edit-icon"
                (click)="openUpdateModal('phone')"
              >
                <img
                  src="../../assets/images/icons_new/options.svg"
                  alt="Edit"
                  class="options-icon me-2"
                />
              </div>
            </div>
            <div class="profile-info-container">
              <div class="input-col">
                <label for="">Date of Birth *</label>
                <input
                  type="text"
                  value="{{ userProfile.date_of_birth }}"
                  [placeholder]="userProfile.date_of_birth"
                  disabled
                />
              </div>
              <div
                class="p-2 pt-4 bg-gray-100 edit-icon"
                (click)="openUpdateModal('dob')"
              >
                <img
                  src="../../assets/images/icons_new/options.svg"
                  alt="Edit"
                  class="options-icon me-2"
                />
              </div>
            </div>
            <div class="profile-info-container">
              <div class="input-col">
                <label for="">ID/Passport *</label>
                <input
                  type="text"
                  value="{{ userProfile.id_or_passport }}"
                  placeholder="{{ user.id_or_passport }}"
                  disabled
                />
              </div>
              <div
                class="p-2 pt-4 bg-gray-100 edit-icon"
                (click)="openUpdateModal('idPassport')"
              >
                <img
                  src="../../assets/images/icons_new/options.svg"
                  alt="Edit"
                  class="options-icon me-2"
                />
              </div>
            </div>
            <div
              class="lower-profile d-flex flex-column items-start justify-content-between w-100"
            >
              <span>Designation</span>
              <span>{{ "- - - " }}</span>
            </div>
            <div
              class="lower-profile d-flex flex-column items-start justify-content-between w-100"
            >
              <span>Reporting Manager</span>
              <span>{{ "- - - " }}</span>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
        <!-- <div class="p-2">
          <div class="flex justify-end mt-2">
            <button
              class="btn btn-secondary text-white rounded-md"
              (click)="openUpdateModal()"
            >
              Update
            </button>
          </div>
        </div> -->
      </div>
    </div>
  </div>

  <!-- Edit user Modal -->
  <div #editModal class="modal">
    <div class="modal-content">
      <h2 class="mt-3 text-center">Edit User</h2>
      <form [formGroup]="userForm">
        <div class="form-col text-center">
          <!-- Phone Number -->
          <div class="form-group w-100" *ngIf="isPhoneField">
            <label for="phone_number">Phone Number</label>
            <input
              type="text"
              id="phone_number"
              class="form-control"
              formControlName="phone_number"
              placeholder="Enter Phone Number"
              maxlength="30"
            />
          </div>
          <!-- ID or Passport -->
          <div class="form-group w-100" *ngIf="isPassField">
            <label for="id_or_passport">ID or Passport</label>
            <input
              type="text"
              id="id_or_passport"
              class="form-control"
              formControlName="id_or_passport"
              placeholder="Enter ID or Passport"
              maxlength="50"
            />
          </div>
           <!-- date of birth -->
          <div class="form-group w-100" *ngIf="isDobField">
            <mat-form-field appearance="fill">
              <mat-label>Date of Birth</mat-label>
              <input matInput [matDatepicker]="picker" formControlName="date_of_birth">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="text-center" *ngIf="errorMessage">
            <h6 class="text-danger">
              {{ errorMessage }}
            </h6>
          </div>
          <div class="modal-buttons-top d-flex justify-content-center">
            <button
              type="button"
              class="btn btn-secondary mx-2"
              (click)="closeUpdateModal()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary mx-2"
              style="background-color: #d8116b"
              (click)="onSubmit()"
            >
              <ng-container *ngIf="isUpdatingUser"> 
                <div class="loader"></div>
              </ng-container>
              <ng-container *ngIf="!isUpdatingUser">
                Update
              </ng-container>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Department Modal -->

  <!-- Group Modal -->

  <!-- Delete Department Confirmation Modal -->

  <!-- Delete Confirmation Modal for Groups -->

  <!-- Add Stage Modal -->

  <!-- Edit Stage Modal -->

  <!-- Delete Confirmation Modal -->
</div>
