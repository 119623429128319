import { Injectable } from '@angular/core';
import { CustomHttpService } from '../custom-http.service';
import { map } from 'rxjs/operators'
import { BehaviorSubject } from 'rxjs';
import { jwtDecode } from "jwt-decode";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService{

  public isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  
  constructor(private customHttp: CustomHttpService, private router: Router) {
    console.log(this.customHttp.apiUrl)
  }

  private readonly TOKEN_KEY = 'auth_token';

  private readonly REFRESH_TOKEN_KEY = 'auth_refresh_token';
  setRefreshToken(token: any): void {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, token);
  }

  getRefreshToken(): string | null {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY);
  }

  removeRefreshToken(): void {
    localStorage.removeItem(this.REFRESH_TOKEN_KEY);
  }

  setToken(token: any): void {
    localStorage.setItem(this.TOKEN_KEY, token);
  }

  getToken(): string | null {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  removeToken(): void {
    localStorage.removeItem(this.TOKEN_KEY);
  }

  isAuthenticated(): boolean {
    const isauth = this.getToken() !== null && this.TOKEN_KEY !== null;
    this.isAuthenticatedSubject.next(isauth)
    return isauth
  }

  user(): any {
    return  jwtDecode(this.getToken() ?? "")
  }

  getUserCompanyId(): string {
    const user = this.user()
    return user.companny_id
  }

  logout () {
    this.removeToken()
    this.removeRefreshToken()
    this.isAuthenticatedSubject.next(false)
    this.router.navigate(['/accounts'])
  }

  isApprover (): boolean {
     return this.user().is_approver
  }
}
