import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFilter'
})
export class DateFilterPipe implements PipeTransform {

  day_month_format = "DD MMM"
  day_month_year_format = "DD MM YYYY"
  time_am_pm_format = "TIME"
  transform(
    value: string, 
    inputFormat: string | Date, 
    outputFormat: string
  ): string {
      // console.log('date pipe',value)
      const date = new Date(value);
      // console.log('date pipe new Date', date.toDateString())

    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    if(this.day_month_format === outputFormat || inputFormat === "") {
      // console.log(date)
      const day = date.getDate()
      return  `${day.toString().padStart(2, '0')} ${date.toLocaleString('en-US', { month: 'short' })}`;
    }

    if(this.day_month_year_format === outputFormat || inputFormat === "") {
      // console.log(date)
      const day = date.getDate()
      return  `${day.toString().padStart(2, '0')} ${date.toLocaleString('en-US', { month: 'long' })}, ${date.getFullYear()}`;
    }

    if (this.time_am_pm_format === outputFormat) {

      // Convert to 12-hour format with AM/PM
      const hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0"); // Ensure two digits
      const period = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM

      const time12HourFormat = `${formattedHours}:${minutes} ${period}`;
      return time12HourFormat
    }

    return date.toDateString()
  }

}
