import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {AuthService} from "../services/accounts/auth.service";
import {environment} from "../../environment/environment";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Check if the 'Authorization' header already exists in the request
    const excludedUrls = [`/reset`, '/verify'];
    if (excludedUrls.some(url => req.url.includes(url))) {
      const authReq = req.clone({
        headers: req.headers.delete('Authorization')
      });

      return next.handle(authReq).pipe(
        catchError((error:any) => {
          if(error.status === 401) {
            console.log("Authetication failed ")
            this.auth.logout()
          }
          if (error.status === 307) {
            const redirectUrl = error.headers.get('Location');
            if (redirectUrl) {
              console.log('Custom redirect to:', redirectUrl);
            }
          }
          return throwError(error);
        }))

    }

    if (req.headers.has('Authorization')) {
      // Header already exists, pass the request through unchanged
      // console.log("am here")
      return next.handle(req).pipe(
        catchError((error:any) => {
          console.log(error)
          if(error.status === 401) {
            console.log("Authetication failed ")
            this.auth.logout()
          }
          if (error.status === 307) {
            const redirectUrl = error.headers.get('Location');
            if (redirectUrl) {
              console.log('Custom redirect to:', redirectUrl);
            }
          }
          return throwError(error);
        })
      );
    }

    // Header does not exist, add the 'Authorization' header
    const authToken = `Bearer ${this.auth.getToken()}`; // Replace with your auth token
    const authReq = req.clone({
      setHeaders: {
        Authorization: authToken
      }
    });
    // Pass the modified request with added header to the next interceptor or handler
    return next.handle(authReq).pipe(
      catchError((error:any) => {
        if(error.status === 401) {
          this.auth.logout()
        }
        if (error.status === 307) {
          const redirectUrl = error.headers.get('Location');
          if (redirectUrl) {
            console.log('Custom redirect to:', redirectUrl);
          }
        }
        return throwError(error);
      }))
  }

  private shouldExcludeHeaders(url: string): boolean {
    // const excludedRoutes = ['/login', '/accounts']; // Add your excluded routes here
    // return excludedRoutes.some(route => url.includes(route));
    return false
  }
}
