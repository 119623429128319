<header  class="p-2 mb-2 border-bottom header-shadow header bg-white">
    <div class="">
        <div class="row">
            <div class="col-md-12 text-end">
                <div class="dropdown">
                    <span [hidden]="!auth.isApprover()" class="mx-4 header_title">KES {{ company.account_balance |  number: '2.0-1'}}</span>

                    <!--<img src="./assets/Menu sections/filter/flag-of-the-united-states-computer-icons-usa-gerb-24f7b05b1b2bdd5109fe660fbe8a9c83 1.png"
                        alt="">-->
                    <!--<img class="mx-4" src="./assets/Menu sections/features/Component 27.png" alt="">-->
                    <img src="./assets/Menu sections/icons/Rectangle 1058.png" alt="">
                    <button class="btn  dropdown-toggle header_dropdown" type="button" id="dropdownMenuButton1"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        {{user.first_name}} <img class="mx-3" src="./assets/images/dropdown.png" alt="">
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li><a class="dropdown-item" (click)="logout()">Logout</a></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>
