<div class="container">
  <div class="row p-5">
    <!--<div class="col-md-12 text-center">
      <img id="map" src="./assets/Menu sections/map_2.jpg" alt="" class="img-fluid">
    </div>-->
    <div class="col-md-12">
      <div class="row" *ngIf="isAdmin">
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title title">Pending Approvals</h5>
              <p class="card-text card-numbers">
                {{ totalNumberOfPendingApprovals }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title title">Pending Approvals in KES</h5>
              <p class="card-text card-numbers">
                {{ totalAmountOfPendingApprovals | number : "2.0-1" }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title title">Total Amount Spent on Trips</h5>
              <p class="card-text card-numbers">
                {{ totalAmountOfApprovals | number : "2.0-1" }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-10 custom-column mt-2 p-4">
      <h2><b>Pending Approvals</b></h2>
    </div>
    <div class="col-12 p-3">
      <div class="row">
        <div class="col-md-9">
          <div class="row">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item col-lg-6" role="presentation">
                <button
                  class="nav-link active trips_tabs"
                  id="pending-tab"
                  [class.active]="activeTab === 'pending'"
                  (click)="switchTab('pending')"
                  type="button"
                  role="tab"
                >
                  Pending Approval
                </button>
              </li>
            </ul>
          </div>
        </div>
        <!--<div class="col-md-3  text-end">
          <button  type="button" class="btn btn-sm mx-2 filter-btn custom-enabled text-end">
            <img src="./assets/Menu sections/Travel Management/Icon.jpg" alt=""> Filter
          </button>

          <button  type="button" class="btn btn-sm mx-2 exp-btn custom-enabled text-end">
            <img src="./assets/Menu sections/Parcel/Icon.jpg" alt=""> Export
          </button>

        </div>-->
      </div>
      <div class="tab-content mt-4" id="myTabContent">
        <div *ngIf="loader" class="spinner-container">
          <mat-spinner color="accent"></mat-spinner>
        </div>
        <div
          class="tab-pane fade"
          [class.active]="activeTab === 'pending'"
          [class.show]="activeTab === 'pending'"
        >
          <div *ngIf="isTripsloaded">
            <div
              class="row mt-4"
              *ngFor="
                let trip of trips
              "
            >
              <ng-container *ngIf="trip.booking_request.channels === 'flights'">
                <div 
                  class="col-12 mt-4"
                >
                  <div 
                    class="row country_goes_information trip__ticket" 
                    style="position: relative;"
                    data-bs-target="#tripReasonModal"
                  >
                    <div [ngClass]="{ 
                      'approval_status': trip.status == 'PENDING',
                      'approval_fail': trip.status == 'REJECTED',
                      'approval_success': trip.status == 'APPROVED' 
                    }">
                      {{ trip.status }}
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div
                          class="mt-3 d-flex flex-row justify-content-between align-items-center"
                          *ngFor="
                            let t of trip.booking_request.booking_details.tripDetails.slices
                          "
                        >
                          <h5>
                            {{ t.origin.name }}
                          </h5>
                          <img src="assets/images/arrow.svg">
                          <h5>
                            {{ t.destination.name }}
                          </h5>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="d-flex flex-row-reverse align-items-end mt-3">
                          <h6 class="date_title font-bold">
                            {{
                              formatDateToYYYYMM(
                                trip.booking_request.booking_details.tripDetails.slices[0].departing_day
                              )
                            }}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <h4 class="date m-0">
                          KES {{ trip.booking_request.booking_details.tripDetails.total_amount }}/-
                        </h4>
                      </div>
                      <div class="col-6 text-end">
                        <!-- <span>IN REVIEW</span> -->
                        <!-- <h6>Stage Name: {{trip.approval_stage_name}}</h6> -->
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <div
                          class="mt-3 d-flex flex-row justify-between items-center"
                          *ngFor="
                            let t of trip.booking_request.booking_details.tripDetails.slices
                          "
                        >
                          <img 
                            src="https://images.kiwi.com/airlines/64x64/{{t?.segments[0]?.marketing_carrier.iata_code}}.png" 
                            style="width: 32px; height: 32px; border-radius: 50%"
                            alt="logo"
                          />
                          <!-- <img
                            [src]="
                              trip.booking_request.booking_details.tripDetails?.slices[0]
                                ?.segments[0]?.marketing_carrier.logo
                            "
                            style="width: 32px; height: 32px; border-radius: 50%"
                            alt="logo"
                          /> -->
                          <span class="trip__flight_name">
                            {{ t.segments[0].marketing_carrier.name }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="col-6 d-flex flex-row justify-content-between"
                      >
                        <div class="d-flex align-items-center trip__flight_name mt-3">
                          {{ 
                            trip.booking_request.booking_details.passengers[0].given_name 
                            +' '+ 
                            trip.booking_request.booking_details.passengers[0].family_name 
                          }}
                        </div>
                        <!-- <div
                          class="d-flex align-items-center trip__flight_name mt-3"
                        >{{ trip.booking_request.booking_details.passengers[0].email }}</div> -->
                        <div
                          class="d-flex align-items-center trip__flight_name mt-3"
                        >Stage {{trip.approval_stage_sequence}}/{{ trip.approval_total_stages }}</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 d-flex flex-row-reverse">
                        <button
                        type="button btn-cta"
                        class="btn mb-2"
                        style="background-color: #D8116B; color: white; font-weight: 800; font-size: 16px;"
                        (click)="handleTripModal(content,trip)"
                        [disabled]="trip.isLoading"
                      >
                        Approve/Reject
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div
                  class="modal fade"
                  #tripReasonModal
                  id="tripReasonModal"
                  tabindex="-1"
                  aria-labelledby="tripReasonModalLabel"
                  aria-hidden="true"
                > -->
                <ng-template #content let-modal>
                  <div class="modal-header">
                    <h1
                      class="modal-title fs-5 text-center"
                      id="tripReasonModalLabel"
                    >
                      Trip Details
                    </h1>
                    <button
                      type="button"
                      class="btn-close"
                      (click)="hideModal(modal)"
                    >
                      <i class="bi bi-x"></i>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="modal-row d-flex align-items-center justify-content-between">
                      <div class="modal-label">Name ~ </div>
                      <div style="font-weight: 600;">
                        {{ 
                          selectedTrip?.booking_request?.booking_details.passengers[0].given_name 
                          +' '+ 
                          selectedTrip?.booking_request?.booking_details.passengers[0].family_name 
                        }}
                      </div>
                    </div>
                    <div class="modal-row d-flex align-items-center justify-content-between">
                      <div class="modal-label">Travel Reason  ~ </div>
                      <div style="font-weight: 600;">{{ selectedTrip?.booking_request?.booking_details.reason }}</div>
                    </div>
                    <div class="modal-row d-flex align-items-center justify-content-between">
                      <div class="modal-label">Price  ~ </div>
                      <div style="font-weight: 600;">KES {{ selectedTrip?.booking_request?.booking_details.tripDetails.total_amount }}/-</div>
                    </div>
                    <div class="modal-row d-flex align-items-center justify-content-between">
                      <div class="modal-label">Trip type ~</div>
                      <ng-container
                        *ngIf="selectedTrip?.booking_request?.booking_details.tripDetails.slices.length > 1; else notReturnTrip"
                      >
                        <div style="font-weight: 600;"> Return </div>
                      </ng-container>
                      <ng-template #notReturnTrip>
                        <div style="font-weight: 600;"> Single</div>
                      </ng-template>
                    </div>
                    <div class="modal-row d-flex align-items-center justify-content-between">
                      <div class="modal-label">Approval Stage</div>
                      <div style="font-weight: 600;">Stage {{selectedTrip.approval_stage_sequence}}/{{ selectedTrip.approval_total_stages }}</div>
                    </div>
                    <ng-container
                      *ngIf="selectedTrip?.booking_request?.booking_details.tripDetails.slices.length > 1; else notReturn"
                    >
                      <ng-container
                        *ngFor="
                          let t of selectedTrip?.booking_request?.booking_details.tripDetails.slices; index as tripIdx
                        "
                      >
                        <div class="d-flex flex-column">
                          <ng-container
                            *ngIf="tripIdx === 0; else notOutbound"
                          >
                            <span style="background-color: #a9a8a7ad; padding: 5px;">Outbound</span>
                          </ng-container>
                          <ng-template #notOutbound>
                            <span style="background-color: #a9a8a7ad; padding: 5px;">Return</span>
                          </ng-template>
                          <div class="modal-row d-flex align-items-center justify-content-between">
                            <div class="modal-label">Airline ~ </div>
                            <div class="d-flex flex-column align-items-center justify-content-between">
                              <div
                                style="font-weight: 600;"
                              >
                                {{ t?.segments[0]?.operating_carrier?.name }}
                              </div>
                            </div>
                          </div>
                          <div class="modal-row d-flex align-items-center justify-content-between">
                            <div class="modal-label">Flight No. ~ </div>
                            <div style="font-weight: 600;">{{ t?.segments[0]?.operating_carrier?.flight_number }} </div>
                          </div>
                          <div class="modal-row d-flex align-items-center justify-content-between">
                            <div class="modal-label"> Departure Time ~ </div>
                            <div style="font-weight: 600;"> 
                              {{ t?.segments[0]?.departing_at | dateFilter : "YYYY-MM-DDTHH:mm:ss" : "TIME" }},
                              {{ t?.segments[0]?.departing_at | dateFilter : "YYYY-MM-DDTHH:mm:ss" : "DD MM YYYY" }}
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <ng-template #notReturn>
                      <ng-container
                        *ngFor="
                          let t of selectedTrip?.booking_request?.booking_details.tripDetails.slices;
                        "
                      >
                        <div class="d-flex flex-column">
                          <span style="background-color: #a9a8a7ad; padding: 5px;">Outbound</span>
                          <div class="modal-row d-flex align-items-center justify-content-between">
                            <div class="modal-label">Airline ~ </div>
                            <div class="d-flex flex-column align-items-center justify-content-between">
                              <div
                                style="font-weight: 600;"
                              >
                                {{ t?.segments[0]?.operating_carrier?.name }}
                              </div>
                            </div>
                          </div>
                          <div class="modal-row d-flex align-items-center justify-content-between">
                            <div class="modal-label">Flight No. ~ </div>
                            <div style="font-weight: 600;">{{ t?.segments[0]?.operating_carrier?.flight_number }} </div>
                          </div>
                          <div class="modal-row d-flex align-items-center justify-content-between">
                            <div class="modal-label"> Departure Time ~ </div>
                            <div style="font-weight: 600;"> 
                              {{ t?.segments[0]?.departing_at | dateFilter : "YYYY-MM-DDTHH:mm:ss" : "TIME" }},
                              {{ t?.segments[0]?.departing_at | dateFilter : "YYYY-MM-DDTHH:mm:ss" : "DD MM YYYY" }}
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-template>
                    <!-- <div class="modal-row d-flex align-items-center justify-content-between">
                      <ng-container
                        *ngIf="selectedTrip?.booking_request?.booking_details.tripDetails.slices.length > 1; else notReturn"
                      >
                        <div class="modal-label">Airlines ~ </div>
                      </ng-container>
                      <ng-template #notReturn>
                        <div class="modal-label">Airline ~ </div>
                      </ng-template>
                      <div class="d-flex flex-column align-items-center justify-content-between">
                        <div
                          style="font-weight: 600;"
                          *ngFor="
                            let t of selectedTrip?.booking_request?.booking_details.tripDetails.slices
                          "
                        >
                          {{ t?.segments[0]?.marketing_carrier?.name }}
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="modal-footer">
                    <!-- <button
                      type="button"
                      class="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button> -->
                    
                    <div 
                      style="position: relative;"
                      class="mb-2 d-flex flex-row flex-row-reverse" 
                      [hidden]="!auth.isApprover()"
                    >
                      <button
                        type="button"
                        class="btn-secondary"
                        (click)="openRejectModal(trip)"
                        style="margin-left: 10px"
                        [disabled]="trip.isLoading"
                      >
                        <ng-container
                          *ngIf="trip.isRejectLoading; else notrejectLoading"
                        >
                          <mat-spinner diameter="20"></mat-spinner>
                        </ng-container>
                        <ng-template #notrejectLoading>
                          <strong
                            class="view_more_btn"
                            style="color: #ffaf4e !important"
                            >Reject</strong
                          >
                        </ng-template>
                      </button>
                      <button
                        type="button"
                        style="background-color: #D8116B;"
                        (click)="approve(modal, trip)"
                        [disabled]="trip.isLoading"
                      >
                        <ng-container *ngIf="trip.isLoading; else notLoading">
                          <mat-spinner diameter="20"></mat-spinner>
                        </ng-container>
                        <ng-template #notLoading>
                          <strong class="text-white" *ngIf="selectedTrip.approval_stage_sequence === selectedTrip.approval_total_stages"
                            >Approve & Pay</strong
                          >
                          <strong class="text-white" *ngIf="selectedTrip.approval_stage_sequence !== selectedTrip.approval_total_stages"
                            >Approve</strong
                          >
                        </ng-template>
                      </button>
                      <div 
                        style="
                          position: absolute;
                          background-color: white;
                          border-radius: 15px;
                          border: 1px gray solid;
                          width: 300px;
                          bottom: 0;
                          right: 0;
                          box-shadow: 2px 1px 39px -8px rgba(0,0,0,0.84);
                          -webkit-box-shadow: 2px 1px 39px -8px rgba(0,0,0,0.84);
                          -moz-box-shadow: 2px 1px 39px -8px rgba(0,0,0,0.84);
                        "
                        *ngIf="trip.booking_request.id === isRejectTripModal"
                      >
                        <div class="modal-content" style="padding: 20px;">
                          <p>Enter a reason for rejecting this trip</p>
                          <input
                            type="text"
                            [(ngModel)]="rejectData.reason"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="Enter Reason"
                          />
                          <div *ngIf="errorStageMessage" class="text-center text-danger my-2">
                            <span>{{errorStageMessage}}</span>
                          </div>
                          <div class="modal-actions mt-3">
                            <button type="button" class="btn-cancel" (click)="closeRejectModal()">
                              Cancel
                            </button>
                            <button
                              type="button"
                              class="btn-secondary"
                              (click)="reject(modal)"
                              style="margin-left: 10px"
                              [disabled]="rejectTrip.isLoading"
                            >
                              <ng-container
                                *ngIf="rejectTrip.isRejectLoading; else notrejectLoading"
                              >
                                <mat-spinner diameter="20"></mat-spinner>
                              </ng-container>
                              <ng-template #notrejectLoading>
                                <strong
                                  class="view_more_btn"
                                  style="color: #ffaf4e !important"
                                  >Reject</strong
                                >
                              </ng-template>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
                <!-- </div> -->
              </ng-container>
              <ng-container *ngIf="trip.booking_request.channels === 'buses'">
                <div class="col-12 mt-4">
                  <div 
                    class="row country_goes_information trip__ticket" 
                    style="position: relative;"
                    data-bs-target="#busTripReasonModal"
                    (click)="handleTripModal(content, trip)"
                  >
                    <div [ngClass]="{ 
                      'approval_status': trip.status == 'PENDING',
                      'approval_fail': trip.status == 'REJECTED',
                      'approval_success': trip.status == 'APPROVED' 
                    }">
                      {{ trip.status }}
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div
                          class="mt-3 d-flex flex-row justify-content-between align-items-center"
                          *ngFor="
                            let t of trip.booking_request.booking_details.tripDetails.slices
                          "
                        >
                          <h5>
                            {{ t.origin.name }}
                          </h5>
                          <img src="assets/images/arrow.svg">
                          <h5>
                            {{ t.destination.name }}
                          </h5>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="d-flex flex-row-reverse align-items-end mt-3">
                          <h6 class="date_title font-bold">
                            {{
                              formatDateToYYYYMM(
                                trip.booking_request.booking_details.tripDetails.slices[0].departing_day
                              )
                            }}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <h4 class="date pt-3">
                          KES {{ trip.booking_request.booking_details.payments.amount }}/-
                        </h4>
                      </div>
                      <div class="col-6 text-end">
                        <!-- <span>IN REVIEW</span> -->
                        <h6>Stage: {{trip.approval_stage_name}}</h6>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-6">
                        <div
                          class="mt-3 d-flex flex-row justify-between items-center"
                        >
                          <img
                            [src]="
                              trip.booking_request.booking_details.operator?.logo
                            "
                            style="width: 32px; height: 32px; border-radius: 50%"
                            alt="logo"
                          />
                          <span class="trip__flight_name">
                            {{ trip.booking_request.booking_details.operator?.alias }}
                          </span>
                        </div>
                      </div>
                      <div
                        class="col-6 d-flex flex-row justify-content-between"
                      >
                        <div class="d-flex align-items-center trip__flight_name mt-3">
                          {{ trip.booking_request.booking_details.passengers[0].seat.id}}
                        </div>
                        <div
                          class="d-flex align-items-center trip__flight_name mt-3"
                        >{{ trip.booking_request.booking_details.passengers[0].seat.class }}</div>
                        <div class="trip__flight_name">
                          Stage {{trip.approval_stage_sequence}}/ {{trip.approval_total_stages}}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-2 d-flex flex-row flex-row-reverse" [hidden]="!auth.isApprover()">
                          <!-- <button
                            type="button"
                            style="background-color: #D8116B;"
                            (click)="approve(trip)"
                            [disabled]="trip.remainingTime <= 0 || trip.isLoading"
                          > -->
                          <!-- <button
                            type="button"
                            style="background-color: #D8116B;"
                            (click)="approve(trip)"
                            [disabled]="trip.isLoading"
                          >
                            <ng-container *ngIf="trip.isLoading; else notLoading">
                              <mat-spinner diameter="20"></mat-spinner>
                            </ng-container>
                            <ng-template #notLoading>
                              <strong class="text-white"
                                >Approve</strong
                              >
                            </ng-template>
                          </button> -->
                          <!-- <button
                            type="button"
                            class="btn-secondary"
                            (click)="reject(template, trip)"
                            style="margin-left: 10px"
                            [disabled]="trip.remainingTime <= 0 || trip.isLoading"
                          > -->
                          <!-- <button
                            type="button"
                            class="btn-secondary"
                            (click)="reject()"
                            style="margin-left: 10px"
                            [disabled]="trip.isLoading"
                          >
                            <ng-container
                              *ngIf="trip.isRejectLoading; else notrejectLoading"
                            >
                              <mat-spinner diameter="20"></mat-spinner>
                            </ng-container>
                            <ng-template #notrejectLoading>
                              <strong
                                class="view_more_btn"
                                style="color: #ffaf4e !important"
                                >Reject</strong
                              >
                            </ng-template>
                          </button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div
                    class="modal fade"
                    id="busTripReasonModal"
                    tabindex="-1"
                    aria-labelledby="busTripReasonModalLabel"
                    aria-hidden="true"
                  > -->
                  <ng-template #content let-bus>
                    <div class="modal-dialog">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1
                            class="modal-title fs-5 text-center"
                            id="busTripReasonModalLabel"
                          >
                            Trip Details
                          </h1>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <i class="bi bi-x"></i>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="modal-row d-flex align-items-center justify-content-between">
                            <div class="modal-label">Name ~ </div>
                            <div style="font-weight: 600;">
                              {{ selectedTrip?.booking_request?.booking_details?.passengers[0]?.full_name }}
                            </div>
                          </div>
                          <div class="modal-row d-flex align-items-center justify-content-between">
                            <div class="modal-label">Price  ~ </div>
                            <div style="font-weight: 600;">KES {{ selectedTrip?.booking_request?.booking_details.tripDetails.total_amount }}/-</div>
                          </div>
                          <div class="modal-row d-flex align-items-center justify-content-between">
                            <div class="modal-label">Trip type ~</div>
                            <ng-container
                              *ngIf="selectedTrip?.booking_request?.booking_details.tripDetails.slices.length > 1; else notReturnTrip"
                            >
                              <div style="font-weight: 600;"> Return </div>
                            </ng-container>
                            <ng-template #notReturnTrip>
                              <div style="font-weight: 600;">One Way</div>
                            </ng-template>
                          </div>
                          <div class="modal-row d-flex align-items-center justify-content-between">
                            <ng-container
                              *ngIf="selectedTrip?.booking_request?.booking_details.tripDetails.slices.length > 1; else notReturn"
                            >
                              <div class="modal-label">Buses ~ </div>
                            </ng-container>
                            <ng-template #notReturn>
                              <div class="modal-label">Bus ~ </div>
                            </ng-template>
                            <div class="d-flex flex-column align-items-center justify-content-between">
                              <div
                                style="font-weight: 600;"
                              >
                                {{ selectedTrip?.booking_request?.booking_details?.operator?.name }}
                              </div>
                            </div>
                          </div>
                          <div class="modal-row d-flex align-items-center justify-content-between">
                            <div class="modal-label">Approval Stage</div>
                            <div style="font-weight: 600;">Stage {{selectedTrip.approval_stage_sequence}}/{{ selectedTrip.approval_total_stages }}</div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <!-- <button
                            type="button"
                            class="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button> -->
                          <div 
                          style="position: relative;"
                          class="mb-2 d-flex flex-row flex-row-reverse" 
                          [hidden]="!auth.isApprover()"
                        >
                          <!-- <button
                            type="button"
                            style="background-color: #D8116B;"
                            (click)="approve(trip)"
                            [disabled]="trip.remainingTime <= 0 || trip.isLoading"
                          > -->
                          <button
                            type="button"
                            style="background-color: #D8116B;"
                            (click)="approve(bus, trip)"
                            [disabled]="trip.isLoading"
                          >
                            <ng-container *ngIf="trip.isLoading; else notLoading">
                              <mat-spinner diameter="20"></mat-spinner>
                            </ng-container>
                            <ng-template #notLoading>
                              <strong class="text-white" *ngIf="selectedTrip.approval_stage_sequence === selectedTrip.approval_total_stages"
                                >Approve & Book</strong
                              >
                              <strong class="text-white" *ngIf="selectedTrip.approval_stage_sequence !== selectedTrip.approval_total_stages"
                                >Approve</strong
                              >
                            </ng-template>
                          </button>
                      
                          <!-- <button
                            type="button"
                            class="btn-secondary"
                            (click)="reject(template, trip)"
                            style="margin-left: 10px"
                            [disabled]="trip.remainingTime <= 0 || trip.isLoading"
                          > -->
                          <button
                            type="button"
                            class="btn-secondary"
                            (click)="openRejectModal(trip)"
                            style="margin-right: 10px"
                            [disabled]="trip.isLoading"
                          >
                            <ng-container
                              *ngIf="trip.isRejectLoading; else notrejectLoading"
                            >
                              <mat-spinner diameter="20"></mat-spinner>
                            </ng-container>
                            <ng-template #notrejectLoading>
                              <strong
                                class="view_more_btn"
                                style="color: #ffaf4e !important"
                                >Reject</strong
                              >
                            </ng-template>
                          </button>
                          <div 
                            style="
                              position: absolute;
                              background-color: white;
                              border-radius: 15px;
                              border: 1px gray solid;
                              width: 300px;
                              bottom: 0;
                              right: 0;
                              box-shadow: 2px 1px 39px -8px rgba(0,0,0,0.84);
                              -webkit-box-shadow: 2px 1px 39px -8px rgba(0,0,0,0.84);
                              -moz-box-shadow: 2px 1px 39px -8px rgba(0,0,0,0.84);
                            "
                            *ngIf="trip.booking_request.id === isRejectTripModal"
                          >
                            <div class="modal-content" style="padding: 20px;">
                              <p>Enter a reason for rejecting this trip</p>
                              <input
                                type="text"
                                [(ngModel)]="rejectData.reason"
                                [ngModelOptions]="{ standalone: true }"
                                placeholder="Enter Reason"
                              />
                              <div *ngIf="errorStageMessage" class="text-center text-danger my-2">
                                <span>{{errorStageMessage}}</span>
                              </div>
                              <div class="modal-actions mt-3">
                                <button type="button" class="btn-cancel" (click)="closeRejectModal()">
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  class="btn-secondary"
                                  (click)="reject(bus)"
                                  style="margin-left: 10px"
                                  [disabled]="rejectTrip.isLoading"
                                >
                                  <ng-container
                                    *ngIf="rejectTrip.isRejectLoading; else notrejectLoading"
                                  >
                                    <mat-spinner diameter="20"></mat-spinner>
                                  </ng-container>
                                  <ng-template #notrejectLoading>
                                    <strong
                                      class="view_more_btn"
                                      style="color: #ffaf4e !important"
                                      >Reject</strong
                                    >
                                  </ng-template>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                  <!-- </div> -->
                </div>
              </ng-container>
              
            </div>
          </div>
          <ng-container
            *ngIf="trips.length === 0 && !loader"
          >
            <div
              class="no-approval-stages">
              <p>No Pending Approvals</p>
            </div>
          </ng-container>
        </div>
        <ng-template #template>
          <div class="modal-body text-center">
            <p>Are you sure you want to reject ?</p>
            <button type="button" class="btn btn-default">Yes</button>
            <button type="button" class="btn btn-primary">No</button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
