import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/accounts/auth.service';
import { CustomHttpService } from '../services/custom-http.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  @ViewChild('editModal') editModal!: ElementRef;

  isEditModalOpen: boolean = false;
  isFetchingApprovalStages: boolean = false;
  isPhoneField: boolean = false;
  isPassField: boolean = false;
  isDobField: boolean = false;
  isUpdatingUser: boolean = false;
  user: any = {};
  userProfile: any = {};
  userForm!: FormGroup;
  errorMessage: string = ''

  constructor(
    public auth: AuthService,
    private customHttpService: CustomHttpService,
    private fb: FormBuilder,
  ){}

  ngOnInit() {
    this.initForm()
    this.user = this.auth.user()
    console.log('user info', this.user)
    this.getUserInfo(this.user.user_id);
    // this.editModal.nativeElement.style.display = 'none';
  }

    initForm() {
      this.userForm = this.fb.group({
        phone_number: ['', Validators.maxLength(30)],
        id_or_passport: ['', Validators.maxLength(50)],
        date_of_birth: ['', Validators.maxLength(12)]
      });
    }

  getUserInfo(userId: string) {
    this.isFetchingApprovalStages = true
    this.customHttpService.getUserById(userId).subscribe(
      (res) => {
        console.log('user profile', res)
        this.userProfile = res;
        this.userProfile['date_of_birth'] = res.date_of_birth.split('-').reverse().join('/')
        this.isFetchingApprovalStages = false;
      },
      (error) => {
        console.error('Error fecthing user by id:', error);
        this.isFetchingApprovalStages = false;
      }
    )
  }

  updateUserProfile(){}

  openUpdateModal(updateField: string){
    if (updateField === 'phone') {
      this.isPhoneField = true;
      this.isPassField = false;
      this.isDobField = false;
      this.userForm.patchValue({
        phone_number: this.userProfile.phone_number
      });
    } else if (updateField === 'idPassport'){
      this.isPhoneField = false;
      this.isPassField = true;
      this.isDobField = false;
      this.userForm.patchValue({
        id_or_passport: this.userProfile.id_or_passport
      });
    } else {
      this.isPhoneField = false
      this.isPassField = false;
      this.isDobField = true;
      this.userForm.patchValue({
        date_of_birth: this.userProfile.date_of_birth
      });
    }
    this.editModal.nativeElement.style.display = 'flex';
  }
  closeUpdateModal(){
    this.editModal.nativeElement.style.display = 'none';
  }
  onSubmit(){
    console.log('user info', this.userForm.value);
    let reqBody = {};

    if (this.isPhoneField) {
      if (!this.userForm.value.phone_number){
        this.errorMessage = `This field cannot be blank. Enter your phone no.`
        setTimeout(() => {this.errorMessage = ''}, 4000);
        return;
      }
      reqBody = {
        phone_number: this.userForm.value.phone_number
      }
      this.updateUser(
        this.userProfile.id,
        reqBody
      )
    } else if (this.isPassField) {
      if (!this.userForm.value.id_or_passport) {
        this.errorMessage = `This field cannot be blank. Enter your id/passport no.`
        setTimeout(() => { this.errorMessage = ''}, 4000);
        return;
      }
      reqBody = {
        id_or_passport: this.userForm.value.id_or_passport
      }
      this.updateUser(
        this.userProfile.id,
        reqBody
      )
    } else if(this.isDobField){
        if (!this.userForm.value.date_of_birth){
          this.errorMessage = `This field cannot be blank. Enter your date of birth.`
          setTimeout(() => {this.errorMessage = ''}, 4000);
          return;
        }
        const dateObj = new Date(this.userForm.value.date_of_birth);

        // Extract YYYY-MM-DD
        const year = dateObj.getFullYear();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Month is 0-based
        const day = dateObj.getDate().toString().padStart(2, "0");

        const formattedDate = `${year}-${month}-${day}`;

        console.log(formattedDate); 

        reqBody = {
          date_of_birth: formattedDate
        }
        this.updateUser(
          this.userProfile.id,
          reqBody
        )
     
    }
  }

  

  // Update an existing user
  updateUser(userId: string, updateBody: any) {
    this.isUpdatingUser = true
    this.customHttpService.updateUser(userId, updateBody).subscribe(
      (response) => {
        this.isUpdatingUser = false
        console.log('profile updated', response)
        this.userProfile = response;
        this.userProfile['date_of_birth'] = response.date_of_birth.split('-').reverse().join('/')
        // this.getUserInfo(userId);  // Refresh the users list
        this.closeUpdateModal(); 
      },
      (error) => {
        this.isUpdatingUser = false
        console.error('Error updating user:', error);
      }
    );
  }
}
