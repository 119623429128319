import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private toastr: ToastrService) { }

  showSuccess(messsage: string, title: string="") {
    this.toastr.success(messsage, title);
  }

  showError(messsage: string, title: string="") {
    this.toastr.error(messsage, title)
  }
}
