<header 
  class="p-2 pb-4 border-bottom header-shadow header"
  [ngClass]="{'bg-white': !isProfilePage, 'bg-gradient': isProfilePage}"
>
  <div class="">
    <div class="row">
      <div class="col-md-12 text-end">
        <div class="dropdown">
          <span 
            [hidden]="!auth.isApprover()" 
            class="mx-4"
            [ngClass]="{'header_title': !isProfilePage, 'header_title_gradient': isProfilePage}"
            >KES {{ company.account_balance | number : "2.0-1" }}</span
          >

          <!--<img src="./assets/Menu sections/filter/flag-of-the-united-states-computer-icons-usa-gerb-24f7b05b1b2bdd5109fe660fbe8a9c83 1.png"
                        alt="">-->
          <!--<img class="mx-4" src="./assets/Menu sections/features/Component 27.png" alt="">-->
          <img src="./assets/Menu sections/icons/Rectangle 1058.png" alt="" />
          <button
            class="btn dropdown-toggle header_dropdown"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            (click)="toggleDropdown()"
          >
            {{ user.first_name + " " + user.last_name }}
            <img class="mx-3" src="./assets/images/dropdown.png" alt="" />
          </button>
          <ul 
            class="dropdown-menu" 
            aria-labelledby="dropdownMenuButton1"
            [ngClass]="{ 'show': showDropdown, 'hidden': showDropdown }"
          >
            <li><a class="dropdown-item" (click)="logout()">Logout</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</header>
