import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TripStateServiceService {
  private ticketIdSource = new BehaviorSubject<string | null >(null);
  ticketId$ = this.ticketIdSource.asObservable();

  private redirectSource = new BehaviorSubject<string | null >(null);
  redirect$ = this.redirectSource.asObservable();

  constructor() { }

  setTicketId(ticketId: string): void {
    this.ticketIdSource.next(ticketId)
  }

  getTicketId(): string | null {
    return this.ticketIdSource.value
  }

  setRedirectName(name: any): void {
    this.redirectSource.next(name)
  }

  getRedirectVal(): string | null {
    return this.redirectSource.value
  }
}
