import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../service/global.service';
import { AuthService } from '../services/accounts/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  companyName: string = '';

  // let peopleMenu =  {'title':'People','url':'/people','icon':'person.png','menu_opened':false,'openedImage':'people_select.png','showSubmenu':false}

  menuList: any = [
    {
      title: 'Home',
      url: '/home',
      icon: 'Icon.svg',
      menu_opened: false,
      openedImage: 'Home_select.png',
      showSubmenu: false,
    },
    {
      title: 'Booking',
      url: '/booking',
      icon: 'calender.png',
      menu_opened: true,
      openedImage: 'booking_select.png',
      showSubmenu: false,
    },
    //'submenu': [
    // {'title': 'Compare fare', 'url': '/compareframe', 'icon': 'down-arrow (copy).png','menu_opened':false,'openedImage':'imageedit_6_6998838893.png'}
    //]
    // },
    {
      title: 'My Trips',
      url: '/booking/trips',
      icon: 'light.png',
      menu_opened: false,
      openedImage: 'airplane.svg',
      showSubmenu: false,
    },
    {
      title: 'Approvals',
      url: '/booking/approvals',
      icon:'Travel-Management_unselect.png',
      menu_opened:false,
      openedImage:'Travel-Management-select.png',
      showSubmenu: false,
    },
    //{'title':'Invoice','url':'/invoice','icon':'invoice.png','menu_opened':false,'openedImage':'invoices_select.png','showSubmenu':false},
    //'submenu': [
    // {'title': 'Payments', 'url': '/payments', 'icon': 'down-arrow (copy).png','menu_opened':false,'openedImage':'imageedit_6_6998838893.png'}
    //]
    // },
    // {'title':'Company Profile','url':'/companyprofile','icon':'enterprise.png','menu_opened':false,'openedImage':'enterprise.png','showSubmenu':false},
    // {'title':'Customer Care','icon':'enterprise.png','menu_opened':false,'openedImage':'enterprise.png','showSubmenu':false},

    /** {'title':'Travel Management','url':'#','icon':'Travel-Management_unselect.png','menu_opened':false,'openedImage':'Travel-Management-select.png','showSubmenu':false,
     'submenu': [
     {'title': 'Travel policies', 'url': 'travelmanagement', 'icon': 'down-arrow (copy).png','menu_opened':false,'openedImage':'imageedit_6_6998838893.png'},
     {'title': 'Approval process', 'url': 'approvalprocess', 'icon': 'down-arrow (copy).png','menu_opened':false,'openedImage':'imageedit_6_6998838893.png'},
     ]
     }**/
    //{'title':'Settings','url':'/asdasd','icon':'setting.png','menu_opened':false,'openedImage':'Icon.svg','showSubmenu':false}
  ];

  bottomMenuList: any = [
    { title: 'Help', url: '', icon: 'help.png' },
    //{'title': 'Logout', 'url': '/sign-in', 'icon': 'exit.png'},
    // {'title': 'Dark', 'url': '/button3', 'icon': 'sun.png'},
    // {'title': 'Light', 'url': '/button4', 'icon': 'half  moon.png'},
  ];

  constructor(public global: GlobalService, public auth: AuthService) {
    this.companyName = this.auth.user().company_name;
  }

  ngOnInit() {
    console.log('User info', this.auth.user());
    if (this.auth.user().is_superuser) {
      this.menuList.push({
        title: 'Company Profile',
        url: '/companyprofile',
        icon: 'enterprise.png',
        menu_opened: false,
        openedImage: 'enterprise.png',
        showSubmenu: false,
      });
      this.menuList.push({
        title: 'Admin',
        url: null, // No direct URL for the parent menu
        icon: 'person.png',
        menu_opened: false,
        openedImage: 'people_select.png',
        showSubmenu: true,
        submenu: [
          {
            title: 'People',
            url: '/people',
            icon: 'user.png',
            openedImage: 'user.png',
          },
          {
            title: 'Company',
            url: '/company',
            icon: 'enterprise.png',
            openedImage: 'enterprise.png',
          },
        ],
      });
      this.menuList.push({
        title: 'Reports',
        url: '/reports',
        icon: 'file.png',
        menu_opened: false,
        openedImage: 'reports_select.png',
        showSubmenu:false
      })
    }
    this.menuList.push({
      title: 'My Profile',
      url: '/userprofile',
      icon: 'profile.svg',
      menu_opened: false,
      openedImage: 'profile.svg',
      showSubmenu: false,
    })
  }

  onMenuItemClick(item: any) {
    // Check if the clicked item is the "Logout" item
    if (item.title === 'Logout') {
      localStorage.removeItem('userdata');
      this.global.redirect('sign-in');
    }
  }

  toggleSubmenu1(menuItem: any): void {
    menuItem.showSubmenu = true;
    this.menuList.forEach((otherItem: any) => {
      if (otherItem !== menuItem) {
        otherItem.menu_opened = false;
        otherItem.showSubmenu = false;
      }
    });
  }

  toggleSubMenu(item: any) {
    item.menu_opened = true;

    this.menuList.forEach((otherItem: any) => {
      // otherItem.menu_opened = false;
      if (otherItem.submenu && otherItem.submenu.length > 0) {
        otherItem.submenu.forEach((submenuItem: any) => {
          if (submenuItem.title != item.title) {
            submenuItem.menu_opened = false;
          }
        });
      }
    });
  }

  toggleMenu(item: any): void {
    item.menu_opened = true;
    this.menuList.forEach((otherItem: any) => {
      if (otherItem !== item) {
        otherItem.menu_opened = false;
      }
      otherItem.showSubmenu = false;
      if (otherItem.submenu && otherItem.submenu.length > 0) {
        otherItem.submenu.forEach((submenuItem: any) => {
          submenuItem.menu_opened = false;

          console.log('Menu name', submenuItem.title);
          console.log('Menu opened or not', submenuItem.menu_opened);
        });
      }
    });

    this.toggleSubmenu1(item);

    // if(item.title == "Travel Management")
    // {
    //   this.toggleSubmenu1(item);
    // }else{
    //   item.menu_opened = true;

    //   this.menuList.forEach((otherItem: any) => {
    //     if (otherItem !== item) {
    //       otherItem.menu_opened = false;
    //     }
    //     otherItem.showSubmenu = false;
    //     if (otherItem.submenu && otherItem.submenu.length > 0) {
    //       otherItem.submenu.forEach((submenuItem : any) =>{
    //         submenuItem.menu_opened=false;

    //         console.log("Menu name",submenuItem.title);
    //         console.log("Menu opened or not",submenuItem.menu_opened);
    //       })
    //   }
    //   });
    // }
  }
  isCustomBackground: boolean = false;

  // Function to toggle background color
  toggleBackgroundColor(): void {
    this.isCustomBackground = !this.isCustomBackground;
  }
}
