<div id="content" *ngIf="isAuthenticated">
  <div class="visible-lg visible-md">
    <app-sidebar></app-sidebar>
  </div>
  <div [ngClass]="global.toggleSidemenu ? 'toggle-space' : 'nospace-toggle' "  [ngClass]="{'sidebar-transition': true}" class="page-wrapper">
    <app-header></app-header>
    <router-outlet></router-outlet>
  </div>
</div>
<router-outlet *ngIf="!isAuthenticated"></router-outlet>
