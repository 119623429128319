
export const environment = {
  production: false,
  base_url              : 'http://localhost:2300/',
  itinerary_client_id: '-6Hf4l6rIG5sSRtXMG7u4A',
  itinerary_client_secret_key: 'FCLxIDudVVNEf76LbfjkmJN3OFjxl27cWgFZcGsjixE',
  //itinerary_base_url: 'https://itinerary.buupass.com/',
  itinerary_base_url: 'https://dev.itinerary.buupass.com/',
  corperate_base_url: 'https://dev.corporateapi.buupass.com'
}

