import { Component, OnInit, inject, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { CustomHttpService } from "../../services/custom-http.service";
import { formatDateToYYYYMM } from "../../service/global.service";
import { AlertsService } from "../../services/alerts.service";
import { AuthService } from "../../services/accounts/auth.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { timer } from 'rxjs';
import { toZonedTime } from 'date-fns-tz';
import * as moment from 'moment-timezone';
import { ActivatedRoute, Router } from '@angular/router';
import { TripStateServiceService } from 'src/app/services/trip-state-service.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';


@Component({
  selector: 'app-tripapprovals',
  templateUrl: './tripapprovals.component.html',
  styleUrls: ['./tripapprovals.component.css']
})
export class TripapprovalsComponent implements OnInit {

  constructor(
    public custom: CustomHttpService,
    public alertService: AlertsService,
    public auth: AuthService,
    private route: ActivatedRoute,
    private tripStateService: TripStateServiceService,
    public router: Router,
    private modalService: NgbModal
  ) { }

  // private modalService = inject(NgbModal);
  trips: any[] = [];
  allTrips: any[] = [];
  isTripsloaded: boolean = true;
  loader: boolean = false;
  newData: any = { type: 'department', name: '', company_id: '', manager_id: '' };
  rejectData: any = { reason: '' }
  approvalStages: any = 0;
  totalNumberOfPendingApprovals = 0;
  totalAmountOfPendingApprovals = 0;
  totalAmountOfApprovals = 0;
  selectedTrip: any = {};
  authToken: string = ''

  redirectedTicketId: any = '';
  redirect: string|null = '';
  activeTab: string = 'pending'; // Default tab

  isAdmin = false;
  isRejectTripModal = '';
  errorStageMessage = '';
  rejectTrip = { 
    isLoading: false, 
    isRejectLoading: false,
    id: '',
    booking_request: {
      id: ''
    }
  }

  // openModal(content: any) {
  //   this.modalService.open(content);
  // }

  // closeModal(modal: any) {
  //   modal.close(); // This will properly close and remove backdrop
  // }

  ngOnInit() {
    this.redirectedTicketId = this.tripStateService.getTicketId()
    console.log('ticket id', this.redirectedTicketId);
    this.redirect = this.tripStateService.getRedirectVal()
    console.log('approver', this.redirect);
    const isAuth = this.auth.isAuthenticated()
    this.authToken = `Bearer ${this.auth.getToken()}`;
    console.log('is auth', isAuth);

    if (!this.redirect) {
      this.route.paramMap.subscribe(params => {
        // Extract the trip ID from the URL
        this.redirectedTicketId = params.get('id');
        this.tripStateService.setTicketId(this.redirectedTicketId)
        console.log('Ticket ID:', this.redirectedTicketId);
      });
      // console.log('Ticket ID:', this.redirectedTicketId);
      this.route.queryParamMap.subscribe(queryParams => {
        this.redirect = queryParams.get('redirect');
        this.tripStateService.setRedirectName(this.redirect)
        console.log('Redirect:', this.redirect);
        if(!isAuth){
          this.router.navigate(['/accounts'])
        } else {
          this.getPendingApprovals();
          this.getTrips()
          // this.fetchApprovalStages();
          if (this.redirect === 'approver') {
            this.activeTab = 'pending'
          } else if (this.redirect === 'traveller'){
            this.activeTab = 'upcoming'
          } else {}
        }
      });
    } else {
      if(!isAuth){
        this.router.navigate(['/accounts'])
      } else {
        this.getPendingApprovals();
        this.getTrips()
        // this.fetchApprovalStages();
      }
    }
 
    // console.log('Redirect:', this.redirect);

    // this.auth.isAuthenticatedSubject.subscribe((value) => {
    //   console.log('Ticket ID:', this.tripStateService.getTicketId());
    //   console.log('Redirect:', this.redirect);
    //   if(value){
    //     this.router.navigate(['/accounts'], {
    //       queryParams: {
    //         redirectFromApproval: 'hjjj-ggg-hhh',
    //         ticketId: 'approver'
    //       }
    //     })
    //   } else {
    //     this.getPendingApprovals();
    //     this.getTrips()
    //     this.fetchApprovalStages();
    //   }
    // });
    if (this.auth.user().is_superuser) {
      // console.log('user', this.auth.user().is_superuser)
      this.isAdmin = this.auth.user().is_superuser
    }
  }

  switchTab(tab: string) {
    this.activeTab = tab; // Change the active tab
  }

  handleTripModal(content: any ,trip: any) {
    this.modalService.open(content);
    console.log('selected trip', trip)
    this.selectedTrip = trip
  }

  downloadPDF(elementId: string, channel: string) {
    let ticketId = ''
    if (channel === 'flights') {
      ticketId = 'flight_ticket'+elementId
    } else {
      ticketId = 'bus_ticket'+elementId
    }
    const element = document.getElementById(ticketId);

    if (!element) {
      console.error('Element not found!');
      return;
    }
    element.style.display = 'block';
    html2canvas(element, { 
      scale: 2,
      useCORS: true, // Enable CORS for external images
      allowTaint: true, // Allows cross-origin images
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
      pdf.save('ticket.pdf');
      element.style.display = 'none';
    });
  }

  // Toggle loader
  isToggleLoader() {
    this.loader = !this.loader;
  }

  // Fetch trips and initialize timers
  getTrips() {
    this.isToggleLoader();
    this.allTrips = [];
    this.custom.getTrips().subscribe(
      (data) => {
        this.resetTotals();
        this.allTrips = data.map(trip => ({
          ...trip,
          isLoading: false,
          isRejectLoading: false,
          remainingTime: this.calculateRemainingTime(trip),
          minutes: 0,
          seconds: 0,
          timer: null
        }));
        console.log('trips', this.allTrips)
        this.updateApprovalStats();
        this.initializeTimers();
        this.isToggleLoader();
      },
      () => this.isToggleLoader()
    );
  }

  openRejectModal(trip: any){
    console.log('reject modal', trip)
    this.rejectTrip = {
      ...this.rejectTrip,
      ...trip
    }
    this.isRejectTripModal = trip.booking_request.id 
  }

  closeRejectModal(){
    this.isRejectTripModal = '' 
  }

  getPendingApprovals(){
    this.isToggleLoader();
    this.trips = [];
    this.custom.getPendingApprovalsV2().subscribe(
      (data) => {
        this.resetTotals();
        this.trips = data.map(trip => ({
          ...trip,
          isLoading: false,
          isRejectLoading: false,
          remainingTime: this.calculateRemainingTime(trip),
          minutes: 0,
          seconds: 0,
          timer: null
        }));
        console.log('trips pending', this.trips)
        this.trips = this.trips
        this.updateApprovalStats();
        this.initializeTimers();
        this.isToggleLoader();
      },
      () => this.isToggleLoader()
    );
  }

  fetchApprovalStages(): void {
    this.custom.getApprovalStages(this.auth.getUserCompanyId()).subscribe(
      (stages: any[]) => {
        this.approvalStages = stages.length;
      },
      (error) => {
        console.error('Error fetching approval stages:', error);
       
      }
    );
  }
  // Reset totals for recalculation
  resetTotals() {
    this.totalNumberOfPendingApprovals = 0;
    this.totalAmountOfPendingApprovals = 0;
    this.totalAmountOfApprovals = 0;
  }

  // Update stats for pending and approved trips
  updateApprovalStats() {
    this.trips.forEach(trip => {
      if (trip.booking_status === 'Pending Approval') {
        this.totalNumberOfPendingApprovals++;
        this.totalAmountOfPendingApprovals += parseFloat(trip.booking_details.payments.amount);
      } else if (trip.booking_status === 'Approved') {
        this.totalAmountOfApprovals += parseFloat(trip.booking_details.payments.amount);
      }
    });
  }

  // Initialize timers for trips
  initializeTimers() {
    this.trips.forEach(trip => {
      if (trip.remainingTime > 0) {
        this.startTripCountdown(trip);
      }
    });
  }

  // Calculate remaining time in milliseconds
  calculateRemainingTime(trip: any): number {
    const expiresAtUTC = trip.booking_details?.tripDetails?.expires_at;
  
    if (!expiresAtUTC) return 0;
  
    // Convert the UTC expiration time to EAT (Africa/Nairobi)
    const expiresAtLocal = moment.utc(expiresAtUTC).local().valueOf();
  
    // Get the current time in EAT for accurate comparison
    const currentTimeLocal = moment().local().valueOf();
  
    console.log(`Expires At (Local): ${expiresAtLocal}, Current Time (Local): ${currentTimeLocal}`);
  
    return Math.max(0, expiresAtLocal - currentTimeLocal);
  }
  
  // Start countdown timer
  startTimersForTrips() {
    this.trips.forEach(trip => {
      if (trip.timer) {
        clearInterval(trip.timer); // Clear any previous timer
      }
      if (trip.remainingTime > 0) {
        this.startTripCountdown(trip);
      }
    });
  }
  
  startTripCountdown(trip: any) {
    trip.timer = setInterval(() => {
      if (trip.remainingTime > 0) {
        trip.remainingTime -= 1000;
        trip.minutes = Math.floor(trip.remainingTime / 1000 / 60);
        trip.seconds = Math.floor((trip.remainingTime / 1000) % 60);
      } else {
        clearInterval(trip.timer);
        trip.remainingTime = 0;
        trip.timer = null;
      }
    }, 1000);
  }
  
  hideModal(modal: any){
    modal.close()
  }

  // Approve a trip
  approve(modal: any,trip: any) {
    const data = { status: "APPROVED", reason: "" };
    trip.isLoading = true;
  
    this.custom.approveV2(trip.id, data, this.authToken).subscribe(
      () => {
        this.alertService.showSuccess("Trip Approved Successfully");
        modal.close(); // This will properly close and remove backdrop
        this.getPendingApprovals(); // Reload trips to get updated expires_at
      },
      () => {
        trip.isLoading = false;
        modal.close(); // This will properly close and remove backdrop
        this.alertService.showError("Trip Approval Failed");
      }
    );
  }
  
  
  // Reject a trip
  reject(modal: any) {
    console.log('reject body', this.rejectTrip)
    const data = { status: "REJECTED", reason: this.rejectData.reason };
    if (!this.rejectData.reason) {
      this.errorStageMessage = 'Enter a reason for rejection'
      setTimeout(() => {
        this.errorStageMessage = ''
      }, 5000);
      return;
    }
    this.rejectTrip.isRejectLoading = true;
    this.custom.approveV2(this.rejectTrip.id, data, this.authToken).subscribe(
      () => {
        modal.close()
        this.alertService.showSuccess("Trip Rejected Successfully");
        this.getPendingApprovals();
        this.isRejectTripModal = ''
      },
      () => {
        modal.close()
        this.rejectTrip.isRejectLoading = false;
        this.isRejectTripModal = ''
        this.alertService.showError("Trip Rejection Failed");
      }
    );
  }
    decline() {

  }

  protected readonly formatDateToYYYYMM = formatDateToYYYYMM;
}
