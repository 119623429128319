import { Component } from '@angular/core';
import { GlobalService } from './service/global.service';
import {AuthService} from "./services/accounts/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'buupass';
  isauth: boolean =  false

  constructor(public global: GlobalService, public auth:AuthService, public  route: Router) {
   this.auth.isAuthenticated()
  }

  isAuthenticated: boolean = false
  ngOnInit() {

    this.auth.isAuthenticatedSubject.subscribe((value) => {
      if(value === false){
        this.route.navigate(['/accounts'])
      }
      this.isAuthenticated = value;

    });

    /**if (this.isauth == false || this.isauth == null || this.isauth == undefined) {
          this.global.redirect('accounts', false)
    }**/
  }

}
