import {Component, OnInit} from '@angular/core';
import {AuthService} from "../services/accounts/auth.service";
import {CustomHttpService} from "../services/custom-http.service";
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{

  user: any = {}
  company:any = {};
  isProfilePage: boolean = false;
  showDropdown: boolean = false;
  private destroy$ = new Subject<void>();

  constructor(
    public auth: AuthService, 
    public customHTTP:CustomHttpService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  logout() {
    this.auth.logout()
  }

  toggleDropdown(){
    this.showDropdown = !this.showDropdown
  }
  getCorperateProfile() {
    this.customHTTP.getBalance(this.user.company_id)
  }

  navigateToUserProfile(){
    this.router.navigate(['/userprofile'])
  }

  ngOnInit() {
    this.user = this.auth.user()
    console.log('user info', this.user)
    this.customHTTP.getBalance(this.auth.getUserCompanyId())
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        {
          next: (data) => this.company = data,
          error: (err) => console.error('Error fetching balance:', err)
        }
    )
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        let currentRoute = this.router.url;  // Full URL path
        console.log('route name', currentRoute)
        // if (currentRoute == '/userprofile') {
        //   this.isProfilePage = true
        // } else {
        //   this.isProfilePage = false
        // }
      });
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
