import {Component, OnInit} from '@angular/core';
import {AuthService} from "../services/accounts/auth.service";
import {CustomHttpService} from "../services/custom-http.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit{

  user: any = {}
  company:any = {}
  constructor(public auth: AuthService, public customHTTP:CustomHttpService) {

  }

  logout() {
    this.auth.logout()
  }

  getCorperateProfile() {
    this.customHTTP.getBalance(this.user.company_id)
  }

  ngOnInit() {
    this.user = this.auth.user()
    this.customHTTP.getBalance(this.auth.getUserCompanyId()).subscribe(
      (next)=>{
        this.company = next
      }
    )
  }


}
