import { Component } from '@angular/core';
import { GlobalService } from '../service/global.service';
import { AuthService } from '../services/accounts/auth.service';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.css']
})
export class HeaderMobileComponent {
  companyName: string = '';
  isCustomBackground: boolean = false;

  menuList:any=[  
    {'title':'Home','url':'/home','icon':'Icon.svg','menu_opened':false,'openedImage':'Home_select.png','showSubmenu':false},
    {'title':'Booking','url':'/booking','icon':'calender.png','menu_opened':true,'openedImage':'booking_select.png','showSubmenu':false},
    {'title':'My Trips','url':'/booking/trips','icon':'light.png','menu_opened':false,'openedImage':'airplane.svg','showSubmenu':false},
    {'title':'Approvals','url':'/booking/approvals','icon':'light.png','menu_opened':false,'openedImage':'airplane.svg','showSubmenu':false},
    {'title':'Company Profile','url':'/companyprofile','icon':'enterprise.png','menu_opened':false,'openedImage':'enterprise.png','showSubmenu':false},
  ]

  bottomMenuList: any = [
    {'title': 'Help', 'url': '', 'icon': 'help.png'},
  ];

  constructor (public global:GlobalService, public auth: AuthService){
    this.companyName = this.auth.user().company_name
  }

  toggleSubmenu1(menuItem: any): void {
    menuItem.showSubmenu = true;
    this.menuList.forEach((otherItem: any) => {
      if (otherItem !== menuItem) {
        otherItem.menu_opened = false;
        otherItem.showSubmenu = false;
      }
    });
  }

  toggleSubMenu(item: any) {
    item.menu_opened = true;

    this.menuList.forEach((otherItem: any) => {
        if (otherItem.submenu && otherItem.submenu.length > 0) {
          otherItem.submenu.forEach((submenuItem : any) =>{
           if(submenuItem.title!= item.title){
             submenuItem.menu_opened=false;
           }
          })
      }
    });
  }
  toggleMenu(item: any): void {
    item.menu_opened = true;
    this.menuList.forEach((otherItem: any) => {
      if (otherItem !== item) {
        otherItem.menu_opened = false;
      }
      otherItem.showSubmenu = false;
      if (otherItem.submenu && otherItem.submenu.length > 0) {
        otherItem.submenu.forEach((submenuItem : any) =>{
          submenuItem.menu_opened=false;

          console.log("Menu name",submenuItem.title);
          console.log("Menu opened or not",submenuItem.menu_opened);
        })
    }
    });
    this.toggleSubmenu1(item);
  }
}
