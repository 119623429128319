import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {FlightSearchInterface} from "./itinerary-service.service";
import {environment} from "../../environment/environment";


@Injectable({
  providedIn: 'root'
})
export class CustomHttpService {
  public apiUrl = environment.corperate_base_url; // Replace with your API URL
  public ticketUrl = `${this.apiUrl}/tickets/`; // Replace with your API URL
  public companytUrl = `${this.apiUrl}/corperates/`; // Replace with your API URL
  public userUrl = `${this.apiUrl}/users/`;//Replace with your API URL
  public ticketUrlApprove = `${this.apiUrl}/tickets/`; // Replace with your API URL
  public reportsUrl = `${this.apiUrl}/reports/`; // Replace with your API URL

  constructor(
    private http: HttpClient
  ) {

  }

 // Fetch a list of all companies
//  getCompanies(): Observable<any[]> {
//   return this.http.get<any[]>(this.companytUrl).pipe(
//     map(response => response),
//     catchError(error => {
//       console.error('Error fetching companies:', error);
//       return throwError(error);
//     })
//   );
// }

getFlightScheduleV2( departure:string, destination:string, data:any, authToken: string) {
  let params = new HttpParams();
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      params = params.set(key, data[key]);
    }
  }
  let headers = new HttpHeaders()
  headers = headers.append('Authorization', authToken)
  return this.http
    .get(`${this.apiUrl}v2/schedules/flights/${departure}/${destination}/`, {params: params, headers: headers})
    .pipe(map((response: any) => {
      return response.data
    }))
}

// fetch all pending approvals v2

getPendingApprovalsV2(params: any = {}): Observable<any[]>{
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(`${this.ticketUrlApprove}v2/pending-approvals/`).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching pending approvals:', error);
      return throwError(error);
    })
  );
}

// fetch all pending approvals

getPendingApprovals(params: any = {}): Observable<any[]>{
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(`${this.ticketUrlApprove}pendingapprovals`).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching pending approvals:', error);
      return throwError(error);
    })
  );
}

// fetch all pending approvals

getReports(
  params: any = {}, 
  startDate: any, 
  endDate: any,
  companyId: string,
  departmenId: string,
  status: string,
  channel: string
): Observable<any>{
  let httpParams = new HttpParams();
  // console.log('pagination val', params)
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any>(
    `${this.reportsUrl}tickets/?page=${params}&start_date=${startDate}&end_date=${endDate}&company_id=${companyId}&department_id=${departmenId}&booking_status=${status}&booking_channel=${channel}`
  ).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching reports:', error);
      return throwError(error);
    })
  );
}

getReportsCsv(
  params: any = {},
  startDate: any, 
  endDate: any,
  companyId: string,
  departmenId: string,
  status: string,
  channel: string
): Observable<string>{
  let httpParams = new HttpParams();
  // console.log('pagination val', params)
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any>(
    `${this.reportsUrl}tickets/export_csv/?start_date=${startDate}&end_date=${endDate}&company_id=${companyId}&department_id=${departmenId}&booking_status=${status}&booking_channel=${channel}`,
    { responseType: 'text' as 'json'}
  ).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error report csv data', error);
      return throwError(error);
    })
  );
}
  // Fetch a list of all users
getUsersV2(params: any = {}): Observable<any[]> {
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(`${this.userUrl}v2/list-approvers`, { params: httpParams }).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching users:', error);
      return throwError(error);
    })
  );
}

  // Fetch a list of all users
getUsers(params: any = {}): Observable<any[]> {
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(this.userUrl, { params: httpParams }).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching users:', error);
      return throwError(error);
    })
  );
}

   // Fetch a specific user by ID
   getUserById(userId: string): Observable<any> {
    return this.http.get<any>(`${this.userUrl}${userId}`).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error fetching user by ID:', error);
        return throwError(error);
      })
    );
  }

  // Create a new user
  createUser(data: any): Observable<any> {
    return this.http.post<any>(this.userUrl, data).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error creating user:', error);
        return throwError(error);
      })
    );
  }

  // Update an existing user by ID
updateUser(userId: string, data: any): Observable<any> {
  // Construct the endpoint with a trailing slash after the user ID
  const url = `${this.userUrl}${userId}/`;

  // Optionally, add custom headers (e.g., if authentication is needed)
  const headers = new HttpHeaders({
    'Content-Type': 'application/json',
    // Add authentication header if required:
    // 'Authorization': `Bearer ${authToken}`
  });

  console.log('Updating user at URL:', url); // For debugging
  console.log('Payload:', data); // Log the data being sent

  return this.http.patch<any>(url, data, { headers }).pipe(
    map(response => {
      console.log('User updated successfully:', response);
      return response;
    }),
    catchError(error => {
      console.error('Error updating user:', error);
      return throwError(error);
    })
  );
}

//GET all child companies section
getCompaniesV2(params: any = {}): Observable<any[]> {
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(`${this.apiUrl}/corperates/v2/list-child-companies`, { params: httpParams }).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching companies:', error);
      return throwError(error);
    })
  );
}

//Companies section
getCompanies(params: any = {}): Observable<any[]> {
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(this.companytUrl, { params: httpParams }).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching companies:', error);
      return throwError(error);
    })
  );
}

   // Fetch a specific company by ID

   getCompanyById(companyId: string): Observable<any> {
    return this.http.get<any>(`${this.companytUrl}${companyId}/`).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error fetching company by ID:', error);
        return throwError(error);
      })
    );
  }

  // Create a new company

  createCompany(data: any): Observable<any> {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      console.log(`Appending: ${key} = ${data[key]}`); // Debug each field
      formData.append(key, data[key]);
    });
  
    return this.http.post<any>(this.companytUrl, formData).pipe(
      map((response) => response),
      catchError((error) => {
        console.error('Error creating company:', error);
        return throwError(() => new Error('Failed to create company. Please try again.'));
      })
    );
  }
  
  // Update an existing company by ID

  updateCompany(companyId: string, data: any): Observable<any> {
    return this.http.put<any>(`${this.companytUrl}${companyId}/`, data).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error updating company:', error);
        return throwError(error);
      })
    );
  }

  //delete company
  deleteCompany(companyId: string): Observable<any> {
    return this.http.delete<any>(`${this.companytUrl}${companyId}/`).pipe(
      map(response => response),
      catchError(error => {
        console.error('Error deleting company:', error);
        return throwError(error);
      })
    );
  }

    // Fetch a list of all departments
getDepartmentsV2(params: any = {}): Observable<any[]> {
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(`${this.apiUrl}/corperates/departments/v2/list-departments`, { params: httpParams }).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching departments:', error);
      return throwError(error);
    })
  );
}
  // Fetch a list of all departments
getDepartments(params: any = {}): Observable<any[]> {
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(`${this.apiUrl}/corperates/departments/`, { params: httpParams }).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching departments:', error);
      return throwError(error);
    })
  );
}
// Fetch a specific department by ID
getDepartmentById(departmentId: string): Observable<any> {
  return this.http.get<any>(`${this.apiUrl}/corperates/departments/${departmentId}/`).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching department by ID:', error);
      return throwError(error);
    })
  );
}

// Create a new department
createDepartment(data: any): Observable<any> {
  return this.http.post<any>(`${this.apiUrl}/corperates/departments/`, data).pipe(
    map((response) => response),
    catchError((error) => {
      console.error('Error creating department:', error);
      return throwError(() => new Error('Failed to create department. Please try again.'));
    })
  );
}

// Update an existing department by ID
updateDepartment(departmentId: string, data: any): Observable<any> {
  return this.http.put<any>(`${this.apiUrl}/corperates/departments/${departmentId}/`, data).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error updating department:', error);
      return throwError(error);
    })
  );
}

// Delete a department by ID
deleteDepartment(departmentId: string): Observable<any> {
  return this.http.delete<any>(`${this.apiUrl}/corperates/departments/${departmentId}/`).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error deleting department:', error);
      return throwError(error);
    })
  );
}

// Fetch a list of all groups
getGroups(params: any = {}): Observable<any[]> {
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(`${this.apiUrl}/corperates/groups/`, { params: httpParams }).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching groups:', error);
      return throwError(error);
    })
  );
}

// Fetch a specific group by ID
getGroupById(groupId: string): Observable<any> {
  return this.http.get<any>(`${this.apiUrl}/corperates/groups/${groupId}/`).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching group by ID:', error);
      return throwError(error);
    })
  );
}

// Create a new group
createGroup(data: any): Observable<any> {
  return this.http.post<any>(`${this.apiUrl}/corperates/groups/`, data).pipe(
    map((response) => response),
    catchError((error) => {
      console.error('Error creating group:', error);
      return throwError(() => new Error('Failed to create group. Please try again.'));
    })
  );
}

// Update an existing group by ID
updateGroup(groupId: string, data: any): Observable<any> {
  return this.http.put<any>(`${this.apiUrl}/corperates/groups/${groupId}/`, data).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error updating group:', error);
      return throwError(error);
    })
  );
}

// Delete a group by ID
deleteGroup(groupId: string): Observable<any> {
  return this.http.delete<any>(`${this.apiUrl}/corperates/groups/${groupId}/`).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error deleting group:', error);
      return throwError(error);
    })
  );
}

// Fetch all roles
getRoles(params: any = {}): Observable<any[]> {
  let httpParams = new HttpParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      httpParams = httpParams.set(key, params[key]);
    }
  }
  return this.http.get<any[]>(`${this.apiUrl}/corperates/roles/`, { params: httpParams }).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching roles:', error);
      return throwError(error);
    })
  );
}

// Fetch a specific role by ID
getRoleById(roleId: string): Observable<any> {
  return this.http.get<any>(`${this.apiUrl}/corperates/roles/${roleId}/`).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error fetching role by ID:', error);
      return throwError(error);
    })
  );
}

// Create a new role
createRole(data: any): Observable<any> {
  return this.http.post<any>(`${this.apiUrl}/corperates/roles/`, data).pipe(
    map((response) => response),
    catchError((error) => {
      console.error('Error creating role:', error);
      return throwError(error);
    })
  );
}


// Update an existing role by ID
updateRole(roleId: string, data: any): Observable<any> {
  return this.http.put<any>(`${this.apiUrl}/corperates/roles/${roleId}/`, data).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error updating role:', error);
      return throwError(error);
    })
  );
}

// Delete a role by ID
deleteRole(roleId: string): Observable<any> {
  return this.http.delete<any>(`${this.apiUrl}/corperates/roles/${roleId}/`).pipe(
    map(response => response),
    catchError(error => {
      console.error('Error deleting role:', error);
      return throwError(error);
    })
  );
}

// Fetch approval stages for a specific company
getApprovalStages(companyId: string): Observable<any[]> {
  const url = `${this.companytUrl}${companyId}/approval-stages/`;
  console.log('Fetching approval stages from:', url); // Debugging purpose
  return this.http.get<any[]>(url).pipe(
    map((response) => {
      console.log('Approval stages fetched successfully:', response);
      return response;
    }),
    catchError((error) => {
      console.error('Error fetching approval stages:', error);
      return throwError(
        () => new Error('Failed to fetch approval stages. Please try again.')
      );
    })
  );
}

// Create an approval stage for a specific company
createApprovalStage(companyId: string, approvalStageData: any): Observable<any> {
  const url = `${this.companytUrl}${companyId}/approval-stages/`;
  console.log('Creating approval stage at:', url); // Debugging purpose
  console.log('Approval stage data:', approvalStageData); // Debugging purpose
  
  return this.http.post<any>(url, approvalStageData).pipe(
    map((response) => {
      console.log('Approval stage created successfully:', response);
      return response;
    }),
    catchError((error) => {
      console.error('Error creating approval stage:', error);
      return throwError(() => new Error('Failed to create approval stage. Please try again.'));
    })
  );
}

// Reorder approval stages for a specific company
reorderApprovalStages(companyId: string, reorderData: any): Observable<any> {
  const url = `${this.companytUrl}${companyId}/approval-stages/reorder/`;
  console.log('Reordering approval stages at:', url); // Debugging purpose
  console.log('Reorder data:', reorderData); // Debugging purpose

  return this.http.patch<any>(url, reorderData).pipe(
    map((response) => {
      console.log('Approval stages reordered successfully:', response);
      return response;
    }),
    catchError((error) => {
      console.error('Error reordering approval stages:', error);
      return throwError(() => new Error('Failed to reorder approval stages. Please try again.'));
    })
  );
}

// Fetch a specific approval stage by company ID and stage ID
getApprovalStageById(companyId: string, stageId: string): Observable<any> {
  const url = `${this.companytUrl}${companyId}/approval-stages/${stageId}/`;
  console.log('Fetching approval stage from:', url); // Debugging purpose

  return this.http.get<any>(url).pipe(
    map((response) => {
      console.log('Approval stage fetched successfully:', response);
      return response;
    }),
    catchError((error) => {
      console.error('Error fetching approval stage:', error);
      return throwError(() => new Error('Failed to fetch approval stage. Please try again.'));
    })
  );
}

// Update a specific approval stage by company ID and stage ID
patchApprovalStage(companyId: string, stageId: string, updateData: any): Observable<any> {
  const url = `${this.companytUrl}${companyId}/approval-stages/${stageId}/`;
  console.log('Patching approval stage at:', url);
  console.log('Patch data:', updateData);

  return this.http.patch<any>(url, updateData).pipe(
    map((response) => {
      console.log('Approval stage patched successfully:', response);
      return response;
    }),
    catchError((error) => {
      console.error('Error patching approval stage:', error);
      return throwError(() => new Error('Failed to patch approval stage. Please try again.'));
    })
  );
}

updateApprovalStage(companyId: string, stageId: string, updateData: any): Observable<any> {
  const url = `${this.companytUrl}${companyId}/approval-stages/${stageId}/`;
  console.log('Updating approval stage at:', url);
  console.log('Update data:', updateData);

  return this.http.put<any>(url, updateData).pipe(
    map((response) => {
      console.log('Approval stage updated successfully:', response);
      return response;
    }),
    catchError((error) => {
      console.error('Error updating approval stage:', error);
      return throwError(() => new Error('Failed to update approval stage. Please try again.'));
    })
  );
}


// Delete a specific approval stage by company ID and stage ID
deleteApprovalStage(companyId: string, stageId: string): Observable<any> {
  const url = `${this.companytUrl}${companyId}/approval-stages/${stageId}/`;
  console.log('Deleting approval stage at:', url); // Debugging purpose

  return this.http.delete<any>(url).pipe(
    map((response) => {
      console.log('Approval stage deleted successfully:', response);
      return response;
    }),
    catchError((error) => {
      console.error('Error deleting approval stage:', error);
      return throwError(() => new Error('Failed to delete approval stage. Please try again.'));
    })
  );
}


  post<T>(endpoint: string, data: any): Observable<T> {
    return this.http.post<T>(`${this.apiUrl}/${endpoint}`, data);
  }

  get(endpoint: string, data: any): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/${endpoint}`, data);
  }


  getTrips(data:any = {}): Observable<any[]>{
    let params = new HttpParams();
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        params = params.set(key, data[key]);
      }
    }
    return this.http
      .get(this.ticketUrl, { params: params })
      .pipe(map((data: any) => {
        return data
      }));
  }

  getBalance(companyID:any):Observable<any>{
    return this.http.get(`${this.companytUrl}${companyID}`).pipe(map(
      (data:any)=>{
        console.log("Balance Balance")
        return data
      }
    ))
  }

  approve(ticketId:string, data = {}) {
      return this.http
        .post(`${this.ticketUrl}${ticketId}/v2/approve/`, data)
        .pipe(map((data:any) =>{
           return data
        }))
  }

  approveV2<T>(ticketId: string, data: any, authToken: string): Observable<T> {
    let headers = new HttpHeaders()
    headers = headers.append('Authorization', `${authToken}`)
    return this.http
      .post<T>(`${this.ticketUrl}${ticketId}/v2/approve/`, data, {headers:headers})
      .pipe(map((data: any) => {
        return data
      }))
      ;
  }

  reject(ticketId:string, data = {}) {
    return this.http
      .post(`${this.ticketUrl}${ticketId}/v2/approve/`, data)
      .pipe(map((data:any) =>{
        return data
      }))
  }


}
